class Download {
  //对外暴露下载接口。
  downloadFile(fileName, content) {
      let aLink = document.createElement('a');
      let blob = this.base64ToBlob(content); //new Blob([content]);

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
  }

  base64ToBlob(code) {
      let contentType = code.split(':')[1];
      try {
        let raw = window.atob(code);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
        // 处理解码后的字符串
      } catch (e) {
        console.error('提供的字符串不是有效的 base-64 编码：', e);
        // 处理错误或提供错误信息
      }
  }
}
export {Download};
